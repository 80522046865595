body{
  max-width: 100vw;
}

/* .rdt_Table {
  background-color: var(--background-body) !important;
  color: var(--text-main) !important;
}

.rdt_TableRow, .rdt_TableCol, .rdt_TableCol_Sortable, .rdt_Pagination, .rdt_TableCell, .rdt_TableHeader, .rdt_TableFooter, .rdt_TableHead, .rdt_TableHeadRow, .rdt_TableBody, .rdt_ExpanderRow{
  background-color: var(--background-body) !important;
  color: var(--text-main) !important;
}

.rdt_TableBody button{
  color: var(--text-main) !important;
  fill: var(--text-main) !important;
}
.rdt_Pagination button{
  color: var(--text-main) !important;
  fill: var(--text-main) !important;
}

header {
  color: var(--text-main) !important;
  fill: var(--text-main) !important;
  background-color: var(--background-body) !important;
} */

/* a{
  text-decoration: none;
  color: black;
} */

.menu{
  display: flex;
  justify-content: flex-start;
  padding: .1rem;
  list-style-type: none;
}

.menu li {
  padding: 1rem;
}

.release-form{
  padding: 1em;
  display: flex;
  flex-direction: row;

}

.release-form form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 33%;
  padding: 1em;
}

.cover-col{
  width: calc(264px + 2em);
  padding: 1em;
}

.cover-col form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.release-form form label{
  padding-bottom: 1rem;
}


.cover{
  width: 128px;
}


.covers{
  display: flex;
}

.tracks-col{
  width: 100%;
  padding: 1em;
}

.tracks-col form {
  padding: 1em;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

input[type=submit]{
  margin-top: 0.5em;
}

label{
  /* margin-top: 1em; */
}

.inline{
  /* padding-top: 1em;
  padding-bottom: 1em; */
}

.tracklist{
  max-height: 100px;
  overflow: scroll;
  padding: 1em;
}

.page{
  padding: 1em;
}