body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  /* --background-body: #fff;
  --background: #efefef;
  --background-alt: #f7f7f7;
  --selection: #9e9e9e;
  --text-main: #363636;
  --text-bright: #000;
  --text-muted: #70777f;
  --focus: #0096bfab;
  --border: #dbdbdb;
  --code: #000; */
  --animation-duration: 0.2s;
  --button-hover: #0076d1;
  /* --scrollbar-thumb: color-mod(var(--button-hover) lightness(-3%));
  --scrollbar-thumb-hover: color-mod(var(--button-hover) lightness(-10%));
  --form-placeholder: #949494;
  --form-text: #000;
  --variable: #39a33c;
  --highlight: #ff0;
  --select-arrow: svg-load('./assets/select-arrow.svg', fill: #161f27); */
  --links: #242c3e;
}

button, input[type=submit]{
  background-color: #242c3e ;
  color: var(--background-alt);
}